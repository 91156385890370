import React from 'react';
import './Policy.scss';
function Policy() {
  return (
    <>
      <div className='header'>PRIVACY POLICY</div>
      <div className='summary'>
        Our policy was last updated on 20-Aug-2022. The application functionality is to collect some statistics about an equipment. 
        We collect email address and name of each user to display in our application. 
        We also collect files which the user may save on cloud during inspection. 
        A user may as well add text/comments regarding each equipment during 
      </div>
      <div className='bottom-text'>Thank you.</div>
    </>
  );
}

export default Policy;
