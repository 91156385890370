import './App.scss'
import logo from './images/logo.png'
import { gsap } from 'gsap'
import { useEffect } from 'react'
import { useRef } from 'react'
import { Helmet } from 'react-helmet'

function Home() {
    const boxRef = useRef()
    const query = gsap.utils.selector(boxRef)
    const timeline = gsap.timeline({ defaults: { ease: 'power1.out' } })

    useEffect(() => {
        timeline.to(query('.text'), { y: '0%', duration: 1, stagger: 1 })
        timeline.to('.slider', { y: '-100%', duration: 1, delay: 0.25 })
        timeline.to('.intro', { y: '-100%', duration: 1 }, '-=1')
        timeline.fromTo(
            '.big_text',
            { opacity: 0 },
            { opacity: 1, duration: 1 },
        )
    }, [])

    return (
        <div className="App">
            <Helmet>
                <title>Alectify Inc</title>
                <meta
                    name="description"
                    content="We are building the digital infrastructure of the electrical industry."
                />
                <meta
                    name="google-site-verification"
                    content="AkkfCmSP0qAF6jeFYZlyBaM1qCpMppHT3EvhLJgsx8w"
                />
            </Helmet>
            <div className="landing">
                <img src={logo} alt="Alectify Inc logo" />

                {/* <a className="policy_link" target="_blank" href="/policy" size="0.1">
                    Privacy Policy
                </a> */}
            </div>

            <div className="big_text">
                <h2 className="big_thing">The next big thing is coming...</h2>
                <p className="stealth_mode">[Stealth mode activated] </p>
            </div>

            <div ref={boxRef} className="intro">
                <div className="intro_text">
                    <h1 className="hide">
                        <span className="text">We are building</span>
                    </h1>
                    <h1 className="hide">
                        <span className="text">
                            the digital infrastructure of
                        </span>
                    </h1>
                    <h1 className="hide">
                        <span className="text">the electrical industry.</span>
                    </h1>
                </div>
            </div>

            <div className="slider"></div>
        </div>
    )
}

export default Home
