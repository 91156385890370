export const VALIDATIONS = {
    first_name: {
        min_length: 'First name cannot be less than 3 characters!',
        max_length: 'First name cannot be greater than 10 characters!',
        alphabets_with_space_only_pattern: /^[A-Za-z ]+$/,
    },
    last_name: {
        min_length: 'Last name cannot be less than 3 characters!',
        max_length: 'Last name cannot be greater than 10 characters!',
        alphabets_with_space_only_pattern: /^[A-Za-z ]+$/,
    },
    phone_number: {
        min_length: 'Phone number cannot be less than 10 digits',
        max_length: 'Phone number cannot be greater than 10 digits',
        digits_only_pattern: /^[0-9]*$/,
    },

    email: {
        not_valid: 'The input is not a valid E-mail!',
        blank: 'Email cannot be blank',
        alphabets_with_space_only_pattern: /^[A-Za-z ]+$/,
    },
    name: {
        min_length: 'Name cannot be less than 3 characters!',
        max_length: 'Name cannot be greater than 20 characters!',
        alphabets_with_space_only_pattern: /^[A-Za-z ]+$/,
    },
    pattern: {
        alphabetsOnly: /^[a-zA-Z]+$/,
        alphanumeric: /^[a-zA-Z0-9\s]+$/,
        digitsOnly: /^[0-9]+$/,
        pmFreq: /^[\d{1,3}]+$/,
        alphanumeric_special: /^[a-zA-Z0-9\s()]+$/,
        floatDigitsOnly: /^-?\d*(\.\d+)?$/,
    },
    generic: {
        zero_chars: 0,
        char_2: 2,
        char_3: 3,
        char_128: 128,
        char_256: 256,
        char_30: 30,
        char_50: 50,
        char_ten: 10,
    },
    generic_length_msgs: {
        char_2: 'Minimum two characters are required',
        max_char_3_month: 'Maximum three digits only',
        char_128: 'Maximum two characters only',
        char_256: 'Maximum two hundred and fifty six characters only',
        char_30: 'Maximum thirty characters only',
        char_50: 'Maximum fifty characters only',
        char_ten: 'Maximum ten characters only',
    },
    required_field: 'This field is required',
    alphabets_only: 'Should be alphabets only!',
    digits_only: 'Should be numbers only!',
    alphanumeric: 'Can contain letters or/and digits.',
    alphanumeric_special: 'Can contain letters or/and digits and/or (,)',
}
