import React from 'react'
import Home from './Home'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Policy from './pages/policy/Policy'
import Help from './pages/help'
function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/policy" element={<Policy />} />

                    <Route exact path="/support" element={<Help />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App
