import { FormOutlined } from '@ant-design/icons'
import { Row, Col, Modal } from 'antd'
import { Form, Input, Button } from 'antd'
import './help.scss'
import { VALIDATIONS } from '../../utils/Validations'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Help = () => {
    const formItemLayout = {
        labelCol: { span: 4 },
    }

    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(true)

    const onFinish = async (values) => {
        console.log(values)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        navigate('/')
    }

    return (
        <div className="modalContent">
            <Modal
                centered={true}
                className="modalBody"
                open={isModalOpen}
                footer={null}
                onCancel={handleCancel}
                getContainer={false}
            >
                <div className="parentBody">
                    <div className="container">
                        <div className="icon">
                            <FormOutlined />
                        </div>
                        <div className="headingBody">
                            <h2>Quick Enquiry</h2>
                            <p>
                                Got a question? Contact us quickly and easily
                                using the following form and we will get back to
                                you ASAP.
                            </p>
                            <Form
                                {...formItemLayout}
                                name="basic"
                                onFinish={onFinish}
                                autoComplete="off"
                                initialValues={{
                                    name: '',
                                    prefix: '+1',
                                }}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message:
                                                        VALIDATIONS.email
                                                            .not_valid,
                                                },
                                                {
                                                    required: true,
                                                    message:
                                                        VALIDATIONS.required_field,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                                {
                                                    pattern:
                                                        VALIDATIONS.name
                                                            .alphabets_with_space_only_pattern,
                                                    message:
                                                        VALIDATIONS.alphabets_only,
                                                },
                                                {
                                                    min: 3,
                                                    message:
                                                        VALIDATIONS.name
                                                            .min_length,
                                                },
                                                {
                                                    max: 20,
                                                    message:
                                                        VALIDATIONS.name
                                                            .max_length,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            name="phone_number"
                                            label="Phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        VALIDATIONS.required_field,
                                                },
                                                {
                                                    pattern:
                                                        VALIDATIONS.phone_number
                                                            .digits_only_pattern,
                                                    message:
                                                        VALIDATIONS.digits_only,
                                                },
                                                {
                                                    min: 10,
                                                    message:
                                                        VALIDATIONS.phone_number
                                                            .min_length,
                                                },
                                                {
                                                    max: 10,
                                                    message:
                                                        VALIDATIONS.phone_number
                                                            .max_length,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            name="description"
                                            label="Enquiry"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter your enquiry!',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea
                                                rows={6}
                                                maxLength={100}
                                                showCount
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button
                                        className="buttonSend"
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        SEND ENQUIRY
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Help
